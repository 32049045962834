import React, { useState, useEffect } from "react";
import styles from "./index.module.scss";
import { getConchMessageApi } from "@/api/modules/home";

import { useNavigate } from "react-router-dom";
import CodeInput from "../codeInput";

import { Toast } from "antd-mobile";
interface propType {
	onhideConchBg: () => void;
}

const ConchLock: React.FC<propType> = props => {
	const { onhideConchBg } = props;
	const [vertifyCode, setVertifyCode] = useState("");
	const navigate = useNavigate();

	// 验证码改变事件
	const handleChange = vertifyCode => {
		setVertifyCode(vertifyCode); // 更新验证码
	};

	// 验证码输入完成后进行校验
	const handleComplete = vertifyCode => {
		console.log("验证码输入完毕", vertifyCode);
		onLookReply();
	};

	// 查看回复
	const onLookReply = () => {
		console.log("查看回复");
		if (vertifyCode.length !== 5) {
			Toast.show({
				icon: "fail",
				content: "请输入5位数字令牌"
			});
			return;
		}

		getConchMessageApi(vertifyCode).then(res => {
			console.log(res);
			if (!res.data) {
				Toast.show({
					icon: "fail",
					content: "令牌无效"
				});
			} else {
				navigate("/pickup?code=" + vertifyCode);
			}
		});
	};

	useEffect(() => {
		// 在这里添加代码以更新key的值
	}, []);

	return (
		<div>
			<div onClick={onhideConchBg} className={styles.couchKeybg}></div>
			<div className={styles.couchKey}>
				<div className={styles.couchKeyTitle}>小海螺令牌</div>
				<div className={styles.centerVertify}>
					<CodeInput onChange={handleChange} onComplete={handleComplete} vertifyCode={vertifyCode} codeNumber={5}></CodeInput>
				</div>
				<div onClick={onLookReply} className={styles.lookReply}>
					输令牌查回复
				</div>
			</div>
		</div>
	);
};
export default ConchLock;
