import React from "react";
import styles from "./index.module.scss";
import conchHead from "@/assets/images/reply/conchHeadImg.png";

interface propType {
	data: any;
	isReplyed: string | number;
	handleReplyClick: (any) => void;
}

const ReplyItem: React.FC<propType> = props => {
	const { data, isReplyed, handleReplyClick } = props;

	const clickBtn = () => {
		handleReplyClick(data);
	};
	return (
		<div className={styles.itemBox}>
			<img src={conchHead} className={styles.img} alt="" />
			<div className={styles.content}>
				<div>
					<div className={styles.time}>{data.createTime}</div>
					<div className={styles.text}>{data.title}</div>
				</div>
				
				<div className={styles.contentBottom}>
					{isReplyed === "2" ? <div className={styles.numReply}>{data.messageCount}条回复</div> : <div></div>}
					<div onClick={clickBtn} className={styles.replyBtn}>
						回复
					</div>
				</div>
			</div>
		</div>
	);
};

export default ReplyItem;
