// counterSlice.ts 文件

import { createSlice } from '@reduxjs/toolkit';

export interface CounterState {
  isShowLeftNav: Boolean;
}
const initialState: CounterState = {
  isShowLeftNav: false,
};

// 创建一个 Slice 
export const counterSlice = createSlice({
  name: 'counter',
  initialState,
  // 定义 reducers 并生成关联的操作
  reducers: {
    // 定义一个加的方法
    openArticleShow: (state) => {
      state.isShowLeftNav =true;
    },
    // 定义一个减的方法
    closeArticleShow: (state) => {
      state.isShowLeftNav =false
    },
  },
});
// 导出加减的方法
export const { openArticleShow, closeArticleShow } = counterSlice.actions;

// 默认导出
export default counterSlice.reducer;
