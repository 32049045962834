/*
 * @Description: 扔海螺頁面
 * @Author: Caroline
 * @Date: 2023-04-24 18:01:22
 * @LastEditTime: 2023-04-27 17:45:12
 * @LastEditors: Caroline
 * @FilePath: \conch-ui\src\view\throw\index.tsx
 */

import React, { useState, useEffect, useRef } from "react";
import { Image, Modal, Input, TextArea, Toast } from "antd-mobile";
import { CloseCircleOutline } from "antd-mobile-icons";
import { useNavigate } from "react-router-dom";
import { throwConchApi } from "@/api/modules/home";
import { getCaptchaApi } from "@/api/modules/login";
import styles from "./index.module.scss";
import iconConch from "@/assets/images/common/icon-conch.png";
import wave from "@/assets/images/common/wave.png";
import BackHomeBtn from "@/assets/images/common/back-home.png";

const ThrowConch: React.FC = () => {
	const navigate = useNavigate();
	const gotoHome = () => {
		navigate("/home");
	};

	// 提交的数据
	const [form, setForm] = useState({
		name: "",
		question: "",
		captcha: ""
	});
	const questionInputRef = useRef(null);
	// 点击偶先不聚焦问题修复
	const onQuestionInputClick = () => {
		if (form.question === "") {
			setTimeout(() => {
				questionInputRef.current.focus();
			}, 500);
		}
	};

	// 验证码区--获取验证码图片
	const [captchaImgUrl, setCaptchaImgUrl] = useState("");
	const [uuid, setUuid] = useState();
	const getCaptchaInfo = () => {
		getCaptchaApi().then(res => {
			const { img = "", uuid } = res.data || {};
			const imgUrl = `data:image/png;base64,${img}`;
			setCaptchaImgUrl(imgUrl);
			setUuid(uuid);
		});
	};
	// 验证码区--初始获取验证码
	useEffect(() => {
		getCaptchaInfo();
	}, []);
	// 验证码区--点击更换验证码
	const onChangeCaptcha = () => {
		getCaptchaInfo();
	};
	// 验证码区--输入的验证码值
	const onCaptchaBlur = e => {
		setForm({
			...form,
			captcha: e.target?.value || ""
		});
	};

	// textarea失去焦点时存储question的值
	const onQuestionBlur = e => {
		setForm({
			...form,
			question: e.target?.value || ""
		});
	};
	// 点击扔海螺
	const [isThrow, setIsThrow] = useState(false);
	const onThrowConch = () => {
		setIsThrow(true);
	};
	useEffect(() => {
		if (isThrow) {
			throwConch();
		}
		return () => {
			setIsThrow(false);
		};
	}, [isThrow]);

	const isClickingRef = useRef(false);
	const throwConch = () => {
		if (form.question.trim()?.length < 10) {
			Toast.show({
				content: "至少输入10个字符~",
				icon: "fail",
				duration: 2000
			});
			return;
		}
		if (isClickingRef.current) {
			return;
		}
		isClickingRef.current = true;
		const params = {
			uuid,
			code: form.captcha,
			name: form.name || "",
			title: form.question || ""
		};
		throwConchApi(params)
			.then((res: any) => {
				setReplyCode(res.data?.code || "");
				setReplyCodeModalOpen(true);
			})
			.catch(() => {
				getCaptchaInfo();
			})
			.finally(() => {
				setTimeout(() => {
					isClickingRef.current = false;
				}, 1000);
			});
	};

	// 扔海螺--回复码
	const [replyCodeModalOpen, setReplyCodeModalOpen] = useState(false);
	const [replyCode, setReplyCode] = useState("");
	const onReplyModalClose = () => {
		setReplyCodeModalOpen(false);
		navigate(-1);
	};
	return (
		<>
			<div className={styles.contentWrap}>
				<div className={styles.backHome}>
					<Image onClick={gotoHome} width="82px" src={BackHomeBtn} />
				</div>
				{/* 问题区 */}
				<div className={styles.questionWrap}>
					<div className={styles.waveImg}>
						<Image width="100%" src={wave} />
					</div>
					<div className={styles.questionTop}>
						<div>Hi，我是小海螺～</div>
						<div>任何你想说的话，都可以偷偷告诉我哟～</div>
					</div>
					<TextArea
						ref={questionInputRef}
						className={styles.questionContent}
						autoFocus
						showCount
						maxLength={1000}
						placeholder="说出你的想法…"
						onBlur={onQuestionBlur}
						onClick={onQuestionInputClick}
					/>
				</div>

				{/* 验证码区 */}
				<div className={styles.captchaInputWrap}>
					<Input className={styles.captchaInput} placeholder="请输入验证码" onBlur={onCaptchaBlur} />
					<Image width={96} height={40} src={captchaImgUrl} />
				</div>
				<div className={styles.captchaChangeBtn}>
					<span onClick={onChangeCaptcha}>看不清？换一张</span>
				</div>

				{/* 提交按钮区 */}
				<div className={styles.throwBtn} onClick={onThrowConch}>
					<Image width={25} src={iconConch} />
					<span className={styles.throwBtnText}>扔出海螺</span>
				</div>
			</div>

			{/* 扔出成功后提示弹层 */}
			<Modal
				className={styles.replyModal}
				visible={replyCodeModalOpen}
				content={
					<div className={styles.replyContent}>
						<CloseCircleOutline
							className={styles.replyCloseBtn}
							style={{ color: "#fff", fontSize: "21px" }}
							onClick={onReplyModalClose}
						/>
						<div className={styles.replyTitle}>小海螺令牌</div>
						<div className={styles.replyCode}>{replyCode || "XXXXX"}</div>
						<div className={styles.replyCheckBtn}>凭令牌 查回复</div>
						<div className={styles.replyTips}>(记得截图保存哟)</div>
					</div>
				}
				closeOnMaskClick={false}
			/>
		</>
	);
};
export default ThrowConch;
