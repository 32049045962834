import React, { useEffect, useState } from "react";
import { InfiniteScroll, DotLoading } from "antd-mobile";
// import InfiniteScroll from "react-infinite-scroll-component";
import styles from "./index.module.scss";
import ReplyItem from "./replyItem";
import { subjectPageList } from "@/api/modules/reply";
import { Tabs } from "antd-mobile";
import { useNavigate } from "react-router-dom";
import { getConchCountApi } from "@/api/modules/home";
import editImg from "@/assets/images/home/editPassword.png";
import {formatDate} from '@/utils/util'

import { LockOutline } from "antd-mobile-icons";
interface DataType {
	code: string;
	createTime: string;
	id: string;
	messageList: any;
	name: string;
	title: string;
}

interface stateType {
	current?: number;
	size?: number;
}

const ReplyList: React.FC = () => {
	const [loading, setLoading] = useState(false);
	const [isHasMore, setIsHasMore] = useState(true);
	const [data, setData] = useState<DataType[]>([]);
	const [page, setPage] = useState<stateType>({
		current: 1,
		size: 10
	});

	const [status, setStatus] = useState<string>("1");

	// 跳转修改密码
	const onEditPassword = () => {
		navigate("/editPassword");
	};

	const loadMoreData = () => {
		if (loading) {
			return;
		}

		setLoading(true);
		const params = {
			...page,
			status,
			'orders[0].column':"create_time",
			'orders[0].asc':false,
			
		};
		return subjectPageList(params)
			.then(res => {
				// 这里就需要+1了，为了下次请求的时候能够拿到下一页的数据
				setPage({
					...page,
					current: page.current + 1
				});
				const list = res.data.records?.map((item) => {
					return {
						...item,
						createTime:formatDate(item.createTime)
					}
				})
				if (params.current == 1) {
					setData([...list]);
				} else {
					setData([...data, ...list]);
				}
				// 因为react不是同步的所以这里需要用乘法进行判断
				if (page.current * page.size >= res.data.total) {
					// 设置为没有更多内容了
					setIsHasMore(false);
				}
				setLoading(false);
			})
			.catch(() => {
				setLoading(false);
			});
	};

	const onChange = (key: string) => {
		setPage({
			...page,
			current: 1
		});
		setIsHasMore(true);
		setData([]);
		setStatus(key);
	};

	const [items, setItem] = useState([
		{
			key: "1",
			label: `待回复(0)`
		},
		{
			key: "2",
			label: `已回复(0)`
		}
	]);

	useEffect(() => {
		getConchCount(1);
		getConchCount(2);
	}, []);

	useEffect(() => {
		loadMoreData();
	}, [status]);

	const getConchCount = value => {
		getConchCountApi({ status: value }).then(res => {
			if (value == 1) {
				items[0].label = `待回复(${res.data})`;
				setItem([...items]);
			} else {
				items[1].label = `已回复(${res.data})`;
				setItem([...items]);
			}
		});
	};
	const navigate = useNavigate();

	// 去回复或查看 1 是待回复 2 是已回复
	const goReplyView = (data: any) => {
		const url = `/pickup?type=reply&code=${data.code}`;
		navigate(url);
	};

	const InfiniteScrollContent = ({ hasMore }: { hasMore?: boolean }) => {
		return (
			<>
				{hasMore ? (
					<>
						<span>loading</span>
						<DotLoading />
					</>
				) : (
					<span>----- 我是有底线的🤐 -----</span>
				)}
			</>
		);
	};
	return (
		<div>
			<div className={styles.tabs}>
				<Tabs
					defaultActiveKey="1"
					activeLineMode="fixed"
					style={{
						"--fixed-active-line-width": "24px",
						"--active-title-color": "#2C69FF"
					}}
					onChange={onChange}
				>
					{items.map(item => {
						return <Tabs.Tab title={item.label} key={item.key} />;
					})}
				</Tabs>
			</div>

			<div className={styles.scrollableDiv}>
				<div>
					{data.map((item, index) => {
						return <ReplyItem handleReplyClick={goReplyView} key={index + "q"} isReplyed={status} data={item}></ReplyItem>;
					})}
				</div>
				<InfiniteScroll loadMore={loadMoreData} hasMore={isHasMore}>
					<InfiniteScrollContent hasMore={isHasMore} />
				</InfiniteScroll>
			</div>

			<div onClick={onEditPassword} className={styles.editPassword}>
				<LockOutline />
			</div>
		</div>
	);
};

export default ReplyList;
