import Login from "@/view/login";
import Home from "@/view/home";
import ThrowConch from "@/view/throw";
import PickupConch from "@/view/pickup";
import ReplyList from "@/view/replyList";
import EditPassword from "@/view/editPassword";

import { Navigate, useRoutes } from "react-router-dom";

export const rootRouter = [
	{
		path: "/",
		element: <Navigate to="/home" />
	},
	{
		path: "/login",
		element: <Login />,
		meta: {
			requiresAuth: false,
			title: "领导登录",
			key: "throwConch"
		}
	},
	{
		path: "/home",
		element: <Home />,
		meta: {
			requiresAuth: false,
			title: "首页",
			key: "home"
		}
	},
	{
		path: "/throw",
		element: <ThrowConch />,
		meta: {
			requiresAuth: false,
			title: "扔海螺",
			key: "throwConch"
		}
	},
	{
		path: "/pickup",
		element: <PickupConch />,
		meta: {
			requiresAuth: false,
			title: "捡海螺",
			key: "pickupConch"
		}
	},
	{
		path: "/replylist",
		element: <ReplyList />,
		meta: {
			requiresAuth: false,
			title: "回复列表",
			key: "reply"
		}
	},
	{
		path: "/editPassword",
		element: <EditPassword />,
		meta: {
			requiresAuth: false,
			title: "修改密码",
			key: "reply"
		}
	}

	// {
	// 	path: "*",
	// 	element: <Navigate to="/404" />
	// }
];

const Router = () => {
	const routes = useRoutes(rootRouter);
	return routes;
};

export default Router;
