import http from "@/api";

/**
 * @name 扔海螺
 */
export const throwConchApi = (params: any) => {
	const { uuid, code, name, title } = params;
	return http.post(`/subject?uuid=${uuid}&code=${code}`, {
		name,
		title
	});
};

/**
 * @name 捡海螺
 */
export const pickupConchApi = (params: any) => {
	const { code } = params;
	return http.get(`/subject/${code}`);
};

// 查看海螺数量
export const getConchCountApi = (params: any) => {
	return http.get<any>(`/subject/count`, params);
};

// 根据验证码查看问题信息
export const getConchMessageApi = (code: any) => {
	return http.get<any>(`/subject/${code}`);
};

/**
 * @name 回复海螺问题
 */
export const replyConchApi = (params: any) => {
	const { subjectId, content } = params;
	return http.post(`/message`, {
		subjectId,
		content
	});
};
