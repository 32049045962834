import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Input, Toast } from "antd-mobile";
import styles from "./index.module.scss";
import conchIcon from "@/assets/images/common/icon-conch.png";
import logo from "@/assets/images/common/logo-dr-text.png";
import { managerLogin } from "@/api/modules/reply";
import { getConchCountApi } from "@/api/modules/home";
import { validToken } from "@/api/modules/reply";
import { EyeInvisibleOutline, EyeOutline } from "antd-mobile-icons";

const ConchLogin: React.FC = () => {
	const navigate = useNavigate();

	const [conchCount, setConchCount] = useState<number | string>(0);
	const [num, setNum] = useState(0); // 待回复的数量
	const [value, setValue] = useState("");
	const [showBlackBg, setShowBlackBg] = useState(false);
	const refInput = useRef(null);

	const [disabled, setDisabled] = useState(false);
	const [visible, setVisible] = useState(false);

	// const [messageApi, contextHolder] = message.useMessage();

	// 捡海螺
	const onComfirm = () => {
		if (disabled) return;
		if (!value) {
			Toast.show({
				content: "请输入密码",
				icon: "fail",
				duration: 2000
			});

			return;
		}
		setDisabled(true);
		managerLogin({ password: value })
			.then(res => {
				if (res.code == "200") {
					localStorage.setItem("token", `Bearer ${res.data?.tokenValue || ""}`);
					setDisabled(false);
					navigate("/replyList");
				} else {
					setDisabled(false);
				}
			})
			.catch(err => {
				setDisabled(false);
			});
	};

	const onInputBlur = () => {
		setShowBlackBg(false);
	};

	const onFocus = () => {
		setShowBlackBg(true);
	};

	// 钩子函数
	useEffect(() => {
		getConchCountApi(null).then(res => {
			console.log(res);
			setConchCount(res.data);
		});

		getConchCountApi({ status: 1 }).then(res => {
			console.log(res);
			setNum(res.data);
		});

		getTokenValid();
	}, []);

	// 判断是否有登录过期
	const getTokenValid = () => {
		validToken().then(res => {
			if (res.data) {
				navigate("/replyList");
			} else {
				localStorage.removeItem("token");
			}
		});
	};

	return (
		<div className={styles.background}>
			{/* {contextHolder} */}
			<div className={styles.topNumConchMessage}>
				<img src={logo} className={styles.leftLogo} alt="" />
				<div className={styles.rightMessage}>
					已有<span className={styles.num}> {conchCount} </span>枚海螺
				</div>
			</div>

			{showBlackBg && <div className={styles.backgroundbg}></div>}

			<div className={styles.buttonBox}>
				<div className={styles.conchNumberText}>
					{num}枚
					<img src={conchIcon} alt="" />
					待回复
				</div>

				<div className={styles.Input}>
					<Input
						value={value}
						onBlur={onInputBlur}
						onFocus={onFocus}
						onChange={value => setValue(value)}
						ref={refInput}
						placeholder="请输入密码"
						type={visible ? "text" : "password"}
						autoFocus
					/>
					<div className={styles.eye}>
						{!visible ? (
							<EyeInvisibleOutline onClick={() => setVisible(true)} />
						) : (
							<EyeOutline onClick={() => setVisible(false)} />
						)}
					</div>
				</div>
				<div className={styles.loginBtn} onClick={onComfirm}>
					登录
				</div>
			</div>
		</div>
	);
};

export default ConchLogin;
