import { RouteObject } from "@/router/interface";

/**
 * @description 获取localStorage
 * @param {String} key Storage名称
 * @return string
 */
export const localGet = (key: string) => {
	const value = window.localStorage.getItem(key);
	try {
		return JSON.parse(window.localStorage.getItem(key) as string);
	} catch (error) {
		return value;
	}
};

/**
 * @description 存储localStorage
 * @param {String} key Storage名称
 * @param {Any} value Storage值
 * @return void
 */
export const localSet = (key: string, value: any) => {
	window.localStorage.setItem(key, JSON.stringify(value));
};

/**
 * @description 清除localStorage
 * @param {String} key Storage名称
 * @return void
 */
export const localRemove = (key: string) => {
	window.localStorage.removeItem(key);
};

/**
 * @description 清除所有localStorage
 * @return void
 */
export const localClear = () => {
	window.localStorage.clear();
};

/**
 * @description 获取需要展开的 subMenu
 * @param {String} path 当前访问地址
 * @returns array
 */
export const getOpenKeys = (path: string) => {
	let newStr: string = "";
	let newArr: any[] = [];
	let arr = path.split("/").map(i => "/" + i);
	for (let i = 1; i < arr.length - 1; i++) {
		newStr += arr[i];
		newArr.push(newStr);
	}
	return newArr;
};

/**
 * @description 递归查询对应的路由
 * @param {String} path 当前访问地址
 * @param {Array} routes 路由列表
 * @returns array
 */
export const searchRoute = (path: string, routes: RouteObject[] = []): RouteObject => {
	let result: RouteObject = {};
	for (let item of routes) {
		if (item.path === path) return item;
		if (item.children) {
			const res = searchRoute(path, item.children);
			if (Object.keys(res).length) result = res;
		}
	}
	return result;
};

/**
 * @description 判断数据类型
 * @param {Any} val 需要判断类型的数据
 * @return string
 */
export const isType = (val: any) => {
	if (val === null) return "null";
	if (typeof val !== "object") return typeof val;
	else return Object.prototype.toString.call(val).slice(8, -1).toLocaleLowerCase();
};

/**
 * @description 对象数组深克隆
 * @param {Object} obj 源对象
 * @return object
 */
export const deepCopy = <T>(obj: any): T => {
	let newObj: any;
	try {
		newObj = obj.push ? [] : {};
	} catch (error) {
		newObj = {};
	}
	for (let attr in obj) {
		if (typeof obj[attr] === "object") {
			newObj[attr] = deepCopy(obj[attr]);
		} else {
			newObj[attr] = obj[attr];
		}
	}
	return newObj;
};

/**
 * @description 生成随机数
 * @param {Number} min 最小值
 * @param {Number} max 最大值
 * @return number
 */
export function randomNum(min: number, max: number): number {
	let num = Math.floor(Math.random() * (min - max) + max);
	return num;
}

/**
 * @description 格式化时间--转为date
 * @param {String} value 传入的时间
 * @returns string
 */
export const getCommonDate = (value: string | number | Date): Date => {
	// safary浏览器里的new Date(参数时间格式只支持"/"分割)，故而此处转一下
	if (typeof value === "string") {
		value = value.replace(/[^\d: ]/g, "/");
	}
	const date = value == "" ? new Date() : new Date(value);
	return date;
};

/**
 * 时间格式化
 */
export const formatDate = (cellValue: string | number | Date, pattern?: string) => {
	if (!cellValue) {
		return "";
	}
	const date = getCommonDate(cellValue);
	const year = date.getFullYear();
	const month = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
	const day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
	const hours = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
	const minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
	const seconds = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
	const format = pattern || "yyyy-MM-dd hh:mm:ss";
	const formatObj = {
		yyyy: year,
		MM: month,
		dd: day,
		hh: hours,
		mm: minutes,
		ss: seconds
	};
	const str = format.replace(/(yyyy|MM|dd|hh|mm|ss)/g, (match: string) => {
		const value = formatObj[match];
		return value || 0;
	});
	return str;
};

/**
 * 参数处理--之所以使用encodeURIComponent包一层,是因为浏览器请求不支持[]等特殊字符，有些场景传参需要[]
 *
 * @param {*} params  参数
 */
export function tansParams(params: { [x: string]: any }) {
	let result = ''
	for (const propName of Object.keys(params)) {
	  const value = params[propName]
	  const part = encodeURIComponent(propName) + '='
	  if (value !== null && value !== '' && typeof value !== 'undefined') {
		if (typeof value === 'object') {
		  for (const key of Object.keys(value)) {
			if (value[key] !== null && value !== '' && typeof value[key] !== 'undefined') {
			  const params = propName + '[' + key + ']'
			  const subPart = encodeURIComponent(params) + '='
			  result += subPart + encodeURIComponent(value[key]) + '&'
			}
		  }
		} else {
		  result += part + encodeURIComponent(value) + '&'
		}
	  }
	}
	return result
  }