import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Input, Toast } from "antd-mobile";
import styles from "./index.module.scss";
import logo from "@/assets/images/common/logo-dr-text.png";
import { changePasswordApi } from "@/api/modules/login";
import { EyeInvisibleOutline, EyeOutline } from "antd-mobile-icons";
const ConchLogin: React.FC = () => {
	const navigate = useNavigate();

	const [oldPassword, setOldPassword] = useState("");
	const [newPassword, setNewPassword] = useState("");
	const [secondPassword, setSecondPassword] = useState("");

	const [showBlackBg, setShowBlackBg] = useState(false);

	const [disabled, setDisabled] = useState(false);

	const [visibleObj, setVisibleObj] = useState({
		oldPassword: false,
		newPassword: false,
		secondPassword: false
	});
	const refInput = useRef(null);

	// 捡海螺
	const onComfirm = () => {
		if (disabled) return;
		if (!oldPassword) {
			Toast.show({
				content: "请输入旧密码",
				icon: "fail",
				duration: 2000
			});
			return;
		}
		if (!newPassword) {
			Toast.show({
				content: "请输入新密码",
				icon: "fail",
				duration: 2000
			});
			return;
		}
		if (!secondPassword) {
			Toast.show({
				content: "请再次输入新密码",
				icon: "fail",
				duration: 2000
			});
			return;
		}
		if (newPassword !== secondPassword) {
			Toast.show({
				content: "两次输入的密码不一致",
				icon: "fail",
				duration: 2000
			});
			return;
		}
		setDisabled(true);
		changePasswordApi({ oldPassword: oldPassword, newPassword: newPassword })
			.then(res => {
				if (res.code == "200") {
					localStorage.removeItem("password");
					Toast.show({
						content: "修改成功,请重新登录",
						icon: "success",
						duration: 2000
					});
					setTimeout(() => {
						setDisabled(false);
						navigate("/login", { replace: true });
					}, 2000);
				} else {
					setDisabled(false);
				}
			})
			.catch(() => {
				setDisabled(false);
			});
	};

	const onInputBlur = () => {
		setShowBlackBg(false);
	};

	const onFocus = () => {
		setShowBlackBg(true);
	};

	// 钩子函数
	useEffect(() => {}, []);

	return (
		<div className={styles.background}>
			{/* {contextHolder} */}
			<div className={styles.topNumConchMessage}>
				<img src={logo} className={styles.leftLogo} alt="" />
			</div>

			{showBlackBg && <div className={styles.backgroundbg}></div>}

			<div className={styles.buttonBox}>
				<div className={styles.Input}>
					<Input
						value={oldPassword}
						onBlur={onInputBlur}
						onFocus={onFocus}
						onChange={value => setOldPassword(value)}
						ref={refInput}
						placeholder="请输入旧密码"
						type={visibleObj.oldPassword ? "text" : "password"}
						autoFocus
					/>
					<div className={styles.eye}>
						{!visibleObj.oldPassword ? (
							<EyeInvisibleOutline onClick={() => setVisibleObj({ ...visibleObj, oldPassword: true })} />
						) : (
							<EyeOutline onClick={() => setVisibleObj({ ...visibleObj, oldPassword: false })} />
						)}
					</div>
				</div>
				<div className={styles.Input}>
					<Input
						value={newPassword}
						onBlur={onInputBlur}
						onFocus={onFocus}
						onChange={value => setNewPassword(value)}
						ref={refInput}
						placeholder="请输入新密码"
						type={visibleObj.newPassword ? "text" : "password"}
					/>
					<div className={styles.eye}>
						{!visibleObj.newPassword ? (
							<EyeInvisibleOutline onClick={() => setVisibleObj({ ...visibleObj, newPassword: true })} />
						) : (
							<EyeOutline onClick={() => setVisibleObj({ ...visibleObj, newPassword: false })} />
						)}
					</div>
				</div>
				<div className={styles.Input}>
					<Input
						value={secondPassword}
						onBlur={onInputBlur}
						onFocus={onFocus}
						onChange={value => setSecondPassword(value)}
						ref={refInput}
						placeholder="请再次输入新密码"
						type={visibleObj.secondPassword ? "text" : "password"}
					/>
					<div className={styles.eye}>
						{!visibleObj.secondPassword ? (
							<EyeInvisibleOutline onClick={() => setVisibleObj({ ...visibleObj, secondPassword: true })} />
						) : (
							<EyeOutline onClick={() => setVisibleObj({ ...visibleObj, secondPassword: false })} />
						)}
					</div>
				</div>

				<div className={styles.loginBtn} onClick={onComfirm}>
					确定
				</div>
			</div>
		</div>
	);
};

export default ConchLogin;
