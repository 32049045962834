import React, { useState, useEffect, useRef } from "react";
import styles from "./index.module.scss";
const CodeInput = props => {
	/**
	 * vertifyCode：验证码
	 * onChange：验证码改变的回调
	 * onComplete：验证码输入完成后的回调
	 */
	const { vertifyCode = "", onChange, onComplete, codeNumber = 6 } = props;

	const [codeArray, setCodeArray] = useState([]); // 用来存放验证码的数组
	const [isFocus, setIsFocus] = useState(false); // 判断是否获取焦点

	const inputList = [...Array(codeNumber)].map((item, index) => index); // 生成模拟输入框数组[0,1,2,3,4,5]

	const inputRef = useRef(null); // 绑定input输入框

	// 获取焦点事件
	const handleInputFocus = () => {
		inputRef.current?.focus(); // 为输入框聚焦
		setIsFocus(true);
	};

	// 获取验证码
	useEffect(() => {
		setCodeArray(vertifyCode.split(""));
	}, [vertifyCode]);

	// 当验证码codeNumber位时，触发完成事件，校验验证码
	useEffect(() => {
		if (vertifyCode.length !== codeNumber) return; // 验证码不足codeNumber位，不触发校验事件
		onComplete && onComplete(vertifyCode);
	}, [vertifyCode, onComplete]);

	// 验证码改变事件
	const handleChange = e => {
		if (e.target.value) {
			// 获取当前输入的数字
			let val = e.target.value;
			onChange?.(val);
		} else {
			onChange?.("");
		}
	};

	// 失去焦点
	const handleBlur = () => {
		setIsFocus(false);
	};

	// 获取焦点
	const handleFocus = () => {
		setIsFocus(true);
	};

	// 默认聚焦input输入框，每次进入都执行
	useEffect(() => {
		handleInputFocus();
	});

	return (
		<div className={styles.codeInputContainer} onClick={handleInputFocus}>
			{/* 验证码数字显示部分 */}
			<div className={styles.numberBox}>
				{inputList.map((item, index) => {
					return (
						<div
							className={styles.inputItem}
							key={index}
							style={{
								// 当验证码的长度等于item时，表示当前正在等待下一位的输入，输入框变色
								border: `1px solid ${item === codeArray.length && isFocus ? "orange" : "#D9D9D9"}`
							}}
						>
							{codeArray[item]}
						</div>
					);
				})}
			</div>
			{/* 输入框，用样式隐藏不显示 */}
			<input
				type="test" // 数字类型输入框
				inputMode="numeric" // 可以弹起数字键盘
				maxLength={codeNumber} // 最大长度是codeNumber
				className={styles.inputValue}
				value={vertifyCode} // value值为输入的验证码
				ref={inputRef}
				onChange={handleChange} // 验证码改变事件
				onBlur={handleBlur} // 失去焦点事件
				onFocus={handleFocus} // 聚焦事件
			/>
		</div>
	);
};

export default CodeInput;
