import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./view/App";
import reportWebVitals from "./reportWebVitals";

// 状态管理
import { Provider } from "react-redux";
import store from "./redux/index";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";

// antd样式
// import "antd/dist/reset.css";

import initReactFastclick from "react-fastclick";
initReactFastclick();

//持久化数据
let persistor = persistStore(store);

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
	<Provider store={store}>
		<PersistGate loading={null} persistor={persistor}>
			<App />
		</PersistGate>
	</Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
