// index.ts 文件
import { configureStore } from "@reduxjs/toolkit";
import storage from 'redux-persist/lib/storage';
// 合并reducers 用的
import { combineReducers  } from "redux";
import { persistReducer } from "redux-persist";

// 创建的reducers 
import counterSlice from "./features/counterSlice";
import tokenSlice from "./features/tokenSlice";



// combineReducers合并reducer
const reducers = combineReducers({
  token: tokenSlice,
  counter: counterSlice,
});

// 配置persist
const persistConfig = {
  key: 'root',
  storage,
  // 黑名单 不缓存的
  blacklist:[''],
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',  //是否开启devTools
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;



// // configureStore创建一个redux数据
// const store = configureStore({
//   // 合并多个Slice
//   reducer: {
//     counter: counterSlice,
//     token:tokenSlice
//   },
// });

// export default store;

