import http from "@/api";

/**
 * @name 扔海螺
 */
// * 确认扔海螺接口
export const subjectPageList = (params: any) => {
	return http.get<any>(`/subject/page/list`, params);
};

//回复人员登录
export const managerLogin = (params: any) => {
	return http.post<any>("/token/login", params);
};

// 验证token
export const validToken = () => {
	return http.get(`/token/valid`);
};
