import { Toast } from "antd-mobile";

/**
 * @description: 校验网络请求状态码
 * @param {Number} status
 * @return void
 */
export const checkStatus = (status: number): void => {
	let content = ''
	switch (status) {
		case 400:
			content = "请求失败！请您稍后重试";
			break;
		case 401:
			content = "登录失效！请您重新登录";
			break;
		case 403:
			content="当前账号无权限访问！";
			break;
		case 404:
			content = "你所访问的资源不存在！";
			break;
		case 405:
			content = "请求方式错误！请您稍后重试";
			break;
		case 408:
			content = "请求超时！请您稍后重试";
			break;
		case 500:
			content = "服务异常！";
			break;
		case 502:
			content = "网关错误！";
			break;
		case 503:
			content = "服务不可用！";
			break;
		case 504:
			content = "网关超时！";
			break;
		default:
			content = "请求失败！";
	}
	Toast.show({
		content,
		icon:'fail',
		duration: 2000
	})
};
