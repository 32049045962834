import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ConchLock from "./components/conchLock"; // Fixed import statement
import styles from "./index.module.scss";
import conchIcon from "@/assets/images/common/icon-conch.png";
import catchIcon from "@/assets/images/common/icon-catch.png";
import logo from "@/assets/images/common/logo-dr-text.png";

import { getConchCountApi } from "@/api/modules/home";

const Home: React.FC = () => {
	const navigate = useNavigate();

	const [showConchLock, setShowConchLock] = useState(false);
	const [conchCount, setConchCount] = useState<number | string>(0);
	// 跳转至扔海螺页面
	const goToThrowConch = () => {
		navigate("/throw");
	};
	// 捡海螺
	const goToPickupConch = () => {
		setShowConchLock(true);
	};

	// 隐藏海螺令牌
	const hideConchBg = () => {
		setShowConchLock(false);
	};

	useEffect(() => {
		getConchCountApi(null).then(res => {
			console.log(res);
			setConchCount(res.data);
		});
	}, []);

	return (
		<div className={styles.background}>
			<div className={styles.topNumConchMessage}>
				<img src={logo} className={styles.leftLogo} alt="" />
				<div className={styles.rightMessage}>
					已有<span className={styles.num}> {conchCount} </span>枚海螺
				</div>
			</div>
			<div className={styles.buttonBox}>
				<div className={styles.throwConch} onClick={goToThrowConch}>
					<img src={conchIcon} alt="" />
					扔海螺
				</div>
				<div className={styles.catchConch} onClick={goToPickupConch}>
					<img src={catchIcon} alt="" />
					捡海螺
				</div>
			</div>

			{showConchLock && <ConchLock onhideConchBg={hideConchBg} />}
		</div>
	);
};
export default Home;
