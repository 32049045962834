/*
 * @Description: 捡海螺页面
 * @Author: Caroline
 * @Date: 2023-04-24 18:01:22
 * @LastEditTime: 2024-02-26 17:42:44
 * @LastEditors: Caroline
 * @FilePath: \conch-ui\src\view\pickup\index.tsx
 */

import React, { useState, useEffect, useRef } from "react";
import { Image, TextArea, Button, Toast } from "antd-mobile";
import { useNavigate, useSearchParams } from "react-router-dom";
import { pickupConchApi, replyConchApi } from "@/api/modules/home";
import styles from "./index.module.scss";
import AvatarReply from "@/assets/images/common/avatar-wave.png";
import AvatarQuestion from "@/assets/images/common/avatar-conch.png";
import ThrowConchBtn from "@/assets/images/common/throw-conch.png";
import BackHomeBtn from "@/assets/images/common/back-home.png";
import {formatDate} from '@/utils/util'


const PickupConch: React.FC = () => {
	const navigate = useNavigate();
	const [URLSearchParams] = useSearchParams();
	const code = URLSearchParams.get("code");
	const type = URLSearchParams.get("type");
	const isReplyPage = type === "reply";

	// 跳转至扔海螺页面
	const gotoHome = () => {
		navigate("/home");
	};

	// 跳转至扔海螺页面
	const gotoThrowConch = () => {
		navigate("/throw");
	};

	useEffect(() => {
		getConchInfo();
	}, []);

	// 获取当前海螺的具体信息
	const [conchInfo, setConchInfo] = useState({
		subjectId: "",
		question: "",
		replyList: []
	});
	const getConchInfo = () => {
		pickupConchApi({ code }).then(res => {
			const { id = "", title = "",createTime = '', messageList } = (res.data || {}) as any;
			setConchInfo({
				subjectId: id,
				question: title,
				createTime: formatDate(createTime),
				replyList: messageList?.map((item) => {
					return {
						...item,
						createTime:formatDate(item.createTime)
					}
				})
			});
		});
	};

	// 回复的内容
	const [replyContent, setReplyContent] = useState("");
	const onReplyContentChange = value => {
		setReplyContent(value || "");
	};
	// 发送问题回复
	const [loading, setLoading] = useState(false);
	const [isToReply, setIsToReply] = useState(false);
	const onSendReply = () => {
		setIsToReply(true);
	};
	useEffect(() => {
		if (isToReply) {
			reply();
		}
		return () => {
			setIsToReply(false);
		};
	}, [isToReply]);
	const isClickingRef = useRef(false);
	const reply = () => {
		if (replyContent.trim() === "") {
			Toast.show({
				content: "回复内容不能为空",
				icon: "fail",
				duration: 2000
			});
			return;
		}
		if (isClickingRef.current) {
			return;
		}
		isClickingRef.current = true;
		setLoading(true);
		const params = {
			subjectId: conchInfo.subjectId,
			content: replyContent
		};
		replyConchApi(params)
			.then(res => {
				Toast.show({
					content: "回复成功~",
					icon: "success",
					duration: 2000
				});
				setReplyContent('')
				getConchInfo();
			})
			.finally(() => {
				setTimeout(() => {
					isClickingRef.current = false;
					setLoading(false);
				}, 1000);
			});
	};

	return (
		<>
			<div className={styles.contentWrap}>
				{/* 顶部标签区 */}
				{!isReplyPage ? (
					<div className={styles.topWrap}>
						<Image src={BackHomeBtn} width="82px" onClick={gotoHome} />
						<Image src={ThrowConchBtn} width="82px" onClick={gotoThrowConch} />
					</div>
				) : null}

				{/* 主体消息区 */}
				<div className={styles.mainWrap}>
					{/* 问题区 */}
					{conchInfo.question ? (
						<div className={`${styles.questionWrap} ${isReplyPage ? styles.questionWrapReply : ""}`}>
							<div className={styles.content}>
								<div className={styles.questionTime}>{conchInfo.createTime}</div>
								<div className={styles.questionTitle}>{conchInfo.question}</div>
							</div>
							<div className={styles.avatar}>
								<Image className={styles.avatar} src={AvatarQuestion} width={40} />
							</div>
						</div>
					) : null}

					{/* 回复列表 */}
					<div>
						{conchInfo?.replyList ? (
							conchInfo?.replyList?.map((item: any) => {
								return (
									<div className={styles.relyItemWrap} key={item.id}>
										<div className={styles.avatar}>
											<Image className={styles.avatar} src={AvatarReply} width={40} />
										</div>
										<div className={styles.replyItem}>
											<div className={styles.replyItemName}>{item.name}&nbsp;&nbsp;{item.createTime}</div>
											<div className={styles.replyItemContent}>{item.content}</div>
										</div>
									</div>
								);
							})
						) : (
							<div className={styles.noReply}>小海螺藏的比较深，暂时还没有人发现它哟～</div>
						)}
					</div>

					{/* 回复输入框 */}
					{isReplyPage ? (
						<div className={styles.replyInputWrap}>
							<TextArea
								value={replyContent}
								className={styles.replyInput}
								placeholder="回复小海螺…"
								onChange={onReplyContentChange}
								autoFocus
								autoSize={{
									maxRows: 4
								}}
								rows={1}
							/>
							<Button className={styles.replySendBtn} loading={loading} color="primary" fill="none" onClick={onSendReply}>
								发送
							</Button>
						</div>
					) : null}
				</div>
			</div>
		</>
	);
};
export default PickupConch;
