import { createSlice } from "@reduxjs/toolkit";

export interface tokenType {
	token: string;
}

const initialState: tokenType = {
	token: ""
};

export const tokenSlice = createSlice({
	name: "token",
	initialState,
	reducers: {
		setToken: (state, { payload }) => {
			state.token = payload.value;
		}
	}
});

export const { setToken } = tokenSlice.actions;

export default tokenSlice.reducer;
